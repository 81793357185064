<template>
    <div>
        <el-button type="primary" @click="bankPage" size="small">返回上一页</el-button>
        <br/>
        <el-descriptions class="margin-top"  :column="3"  border>
                    <el-descriptions-item label="治疗号">
                     {{ admissionNo  }}
                    </el-descriptions-item>
                    <el-descriptions-item label="姓名">
                    {{ info.name }}
                    </el-descriptions-item>
                    <el-descriptions-item label="治疗次数">
                    {{ info.count }}
                    </el-descriptions-item>
                    <el-descriptions-item label="性别">
                    {{ info.sex }}
                    </el-descriptions-item>
                    <el-descriptions-item label="年龄">
                    {{ info.age }}
                    </el-descriptions-item>
                </el-descriptions>
        <el-row>
            <el-col :span="24">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>检查曲线图</span>
                    </div>
                    <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
                </el-card>
            </el-col>
            <el-col :span="24">
               
                <el-card class="box-card">
                    <el-table :data="tableData">
                        <el-table-column
                            prop="id"
                            label="序号"
                            width="180">
                            <template slot-scope="scope">
                                <RouterLink :to="{path:'/home/userdata',query:{admissionNo:admissionNo}}"> {{  scope.row.id }} </RouterLink> 
                                <el-badge :value="scope.row.count" size="small" class="item" ></el-badge>
                            </template>    
                        </el-table-column>

                        <el-table-column
                            prop="startTime"
                            label="日期"
                            width="180">
                        </el-table-column>
                       
                        <el-table-column
                            prop="PostictalSuppressionIndex"
                            label="抑制指数">
                        </el-table-column> 
                        <el-table-column
                            prop="EEGEndpoint"
                            label="脑电波终止时间">
                        </el-table-column> 
                        <el-table-column
                            prop="%EnergySet"
                            label="能量">
                        </el-table-column> 
                        <el-table-column
                            prop="ChargeDelivered"
                            label="电量">
                        </el-table-column>
                        <el-table-column
                            prop="Current"
                            label="电流">
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
            
        </el-row>
    </div>
</template>
<script>
import { UserDetails } from '@/api/user';
function forList(data,list){
    for(const li in list){
        const itm = list[li];
        const key = itm.name;
        const KeyName = key.replace(/\s*/g,""); 
        data[KeyName] = itm.content || null;
     }
}
const optionsName = {
         '%EnergySet' :"能量设定[年龄]",
         ChargeDelivered :"电量[mC]",
         Current :"电流[A]",
        //  StimulusDuration :"时间[Sec]"},
        //  Frequency :"频率(Hz)"},
        //  PulseWidth :"波宽(mSec)"},
        //  DynamicImpedance :"动态电阻(Ohm)"},
        //  StaticImpedance :"静态电阻(Ohm)"},
         EEGEndpoint :"脑电波的发作终止时间(Sec)",
        //  EMGActivity :"肌电波活动"},
        //  BaseHeartRate :"平时心率(b/m)"},
        //  PeakHeartRate :"发作时的心率(b/m)"},
        //  AverageSeizureEnergyIndex :"发作脑电波功率峰值的平均值(µV²)"},
         PostictalSuppressionIndex :"发作抑制的指数(%)",
        //  MaximumSustainedPower :"达到最大持续脑电波的功率(µV²)"},
        //  TimetoPeakPower :"达到最大持续脑电波功率的时间(Sec)"},
        //  MaximumSustainedCoherence :"最大脑电波一致性(%)"},
        //  name24 :"最大脑电波一致性的时间(Sec)"},
        //  EarlyIctalAmplitude :"早期发作振幅(µV)"},
        //  MidictalAmplitude :"中值振幅(µV)"},
        //  Post-IctalAmplitude :"发作后振幅(µV)"},
}
export default {
    data(){
        return {
        tableData:[],
        info:{},
        admissionNo:'',
        chartOptions: {
            title:{
                text:'患者电疗趋势图'
            },
            type: "line",
            xAxis:{
                categories:[],
            },
            series: [ ],
        },
    };
    },
    async created(){
     const admissionNo = this.$route.params.admissionNo;
     const info = this.$route.query;
      this.info = info;
     if(!admissionNo){
        this.$message.info("数据错误");
        this.$router.go(-1);
     }else{
        this.admissionNo = admissionNo;
        //查询检查数据
        const res = await UserDetails({admissionNo});
        const ret = res.data;
        let lists = [];
        for(const li of ret){
         if(!this.name){
            this.info.name = li.name || '无';
            this.age = li.age,
            this.count = 0
         }
         const data = {};
         data.fileName = li.fileName;
         data.startTime = li.startTime;
         data.id = li.id;
         data.count = li.orderNumber;
         forList(data,li.userCureItemsDTOList);
         lists.push(data);
        }
        lists.sort((a,b)=>{
                return a.startTime - b.startTime;
            });
       // console.log("---SORT---",lists);
        this.tableData = lists;
        //曲线图
        this.lineOption(lists);
     }
    },
    methods:{
        bankPage(){
            this.$router.go(-1);
        },
        lineOption(lists){
            const items = {};
            const itemsData = [];
            const time = [];
            
            const okeys = Object.keys( optionsName);
          
           for(const li of lists){
           // console.log("-----i---",li.startTime);
              time.unshift(li.startTime);
              for(const i of okeys){
                 if(items[i] === undefined){
                    items[i] = {name:'',data:[]}
                 };
                 items[i].name = optionsName[i];
                 items[i].data.unshift( parseFloat(li[i]) || null);
              } 
           }
          for(const li in items){
            itemsData.push(items[li]);
          }
           this.chartOptions.series = itemsData;
           this.chartOptions.xAxis = {
            categories:time
           }
        }
    }
}
</script>;