import request from '../utils/request'
// 用户登录
export function getUsers(params) {
    return request({
      url: '/users/list',
      method: 'get',
      params
    });
  }
// 用户信息更改
  export function updateUsers(data) {
    return request({
      url: '/users',
      method: 'put',
      data
    });
  }
  // 删除信息
  export function deleteUsers(id) {
    return request({
      url: `/users/${id}`,
      method: 'delete',
      data:id
    });
  }
  // 患者总详情
  export function UserDetails(params){
    return request({
      url:'/users/details',
      method:'GET',
      params
    })
  }